<template>
  <div class="test">
    <div class="videoBox">
      <video
        autoplay
        controls
        preload="auto"
        src="http://vjs.zencdn.net/v/oceans.mp4"
      ></video>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="less" scoped>
.test {
  // bgcpink 开始/结束/全屏/下载/时间
  height: 100vh;
  margin-top: 100px;
  .videoBox {
    // width: 100vw;
    // overflow: hidden;
  }
  video {
    // height: 500px;
    width: 100%;
    // transform: rotate(90deg);
    // width: 100vh;
    // height: 100vw;
  }
}
</style>
